import { useTranslation } from "react-i18next";
import {
  useEvAllowedCarsQuery,
  useEvChargingSessionsQuery,
} from "../../queries/ev-charging.query";
import { CenteredLoader } from "../ui/CenteredLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { EvChargingSession } from "../../queries/models/ev/ev-charging-session";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { formatNumber } from "../../utils/number-utils";
import { useMemo, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import axiosRequest from "../../services/request";
import { InputSwitch } from "primereact/inputswitch";
import { Tag } from "primereact/tag";
import { useIOConfigurationQuery } from "../../queries/configuration.query";

interface EvChargingSessionsProps {
  installationId: number;
}

export function EvChargingSessions({
  installationId,
}: EvChargingSessionsProps) {
  const { t } = useTranslation();
  const [from, setFrom] = useState<Date>(startOfMonth(new Date()));
  const [to, setTo] = useState<Date>(endOfMonth(new Date()));

  const [showPublicCharging, setShowPublicCharging] = useState(true);
  const [showPrivateCharging, setShowPrivateCharging] = useState(true);

  const { data, isLoading } = useEvChargingSessionsQuery(
    installationId,
    from,
    to
  );

  const { data: carsData, isLoading: isLoadingCars } =
    useEvAllowedCarsQuery(installationId);

  const configurationQuery = useIOConfigurationQuery(installationId);

  const totalEnergyConsumed = useMemo(() => {
    if (!data || !carsData) return 0;
    return data.reduce((acc, rowData) => {
      const sessionCar = carsData.find((car) => car.oldId === rowData.carId);
      if (!sessionCar) return acc;
      if (!showPublicCharging && sessionCar.external) return acc;
      if (!showPrivateCharging && !sessionCar.external) return acc;
      return acc + (rowData.currentKwh - rowData.startKwh);
    }, 0);
  }, [data, carsData, showPrivateCharging, showPublicCharging]);

  const filteredData = useMemo(() => {
    if (!data || !carsData) return [];

    return data.filter((session) => {
      const sessionCar = carsData?.find((car) => car.oldId === session.carId);
      if (!showPublicCharging && sessionCar?.external) return false;
      if (!showPrivateCharging && !sessionCar?.external) return false;
      return true;
    });
  }, [data, carsData, showPublicCharging, showPrivateCharging]);

  const showPrivatePublicToggles = useMemo(() => {
    if (!configurationQuery.data) return false;
    return configurationQuery.data.hasPublicChargingStation;
  }, [configurationQuery.data]);

  function downloadReport() {
    axiosRequest
      .get(
        `ev-charging/${installationId}/cost-report?startDate=${from.toUTCString()}&endDate=${to.toUTCString()}&showPublic=${showPublicCharging}&showPrivate=${showPrivateCharging}`,
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        let filename = "cost-report.pdf"; // default filename
        if (contentDisposition) {
          const match = contentDisposition.match(/filename=([^"]+)/);
          if (match && match[1]) {
            filename = match[1];
          }
        }

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  function getCarColumnBody(rowData: EvChargingSession) {
    const car = carsData?.find((car) => car.oldId === rowData.carId);

    if (!car) return "-";

    return (
      <div>
        {car.name}
        {car.external && (
          <Tag
            severity="info"
            value={t("ev.publicCharging")}
            className="ml-2"
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="flex flex-row justify-between items-center">
          <Calendar
            id="calendar"
            value={from}
            onChange={(e) => {
              setFrom(e.value as Date);
              setTo(endOfMonth(e.value as Date));
            }}
            dateFormat="MM yy"
            view="month"
            className="mb-3 p-inputtext-centered"
          />
          <div className="flex flex-row gap-2 items-center">
            {showPrivatePublicToggles && (
              <>
                <span>{t("ev.showPublicCharging")}</span>
                <InputSwitch
                  checked={showPublicCharging}
                  onChange={(e) => setShowPublicCharging(e.value ?? false)}
                />
                <span>{t("ev.showPrivateCharging")}</span>
                <InputSwitch
                  checked={showPrivateCharging}
                  onChange={(e) => setShowPrivateCharging(e.value ?? false)}
                />
              </>
            )}
            <Button
              label={t("ev.report.downloadButton")}
              onClick={downloadReport}
            />
          </div>
        </div>
        {(isLoading || isLoadingCars) && <CenteredLoader />}
        {!isLoading && filteredData && carsData && (
          <div>
            <DataTable value={filteredData}>
              <Column body={getCarColumnBody} header={t("ev.car")} />
              <Column
                field="startDate"
                header={t("ev.chargingStart")}
                body={(rowData: EvChargingSession) =>
                  format(rowData.startDate, "yyyy-MM-dd HH:mm")
                }
              />
              <Column
                header={t("ev.chargingEnd")}
                body={(rowData: EvChargingSession) =>
                  rowData.endDate === null
                    ? t("ev.stillCharging")
                    : format(rowData.endDate, "yyyy-MM-dd HH:mm")
                }
              />

              <Column
                body={(rowData: EvChargingSession) =>
                  formatNumber(rowData.currentKwh - rowData.startKwh, 2) +
                  " kWh"
                }
                header={t("ev.energyConsumed")}
                footer={
                  <span>
                    {t("common.total") +
                      ": " +
                      formatNumber(totalEnergyConsumed, 2) +
                      " kWh"}
                  </span>
                }
              />
            </DataTable>
          </div>
        )}
      </div>
    </div>
  );
}
